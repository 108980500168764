import {Component} from '@angular/core';
import {
  CtControlConfiguration,
  CtControlTypes, CtControlValidator, CTFieldInfos,
  CtFormContainerHelper,
  CtRangeControlOptions,
  CtThemeTypes
} from "@ctsolution/ct-framework";

const UserWorkingHoursMinuteControl: CTFieldInfos = CTFieldInfos.create({
  Field: "Minute",
  Type: "InputText",
  IsNullable: false,
  ControlType: "InputText"
} as CTFieldInfos);


@Component({
  selector: 'app-user-working-hours-minute-control',
  template: `
    <ct-control [configuration]="control"></ct-control>`
})
export class UserWorkingHoursMinuteControlComponent {

  control: CtControlConfiguration = CtControlConfiguration
    .create(
      CtFormContainerHelper
        .create()
        .getCTFormControlByCTFieldInfo(UserWorkingHoursMinuteControl))
    .setTheme(CtThemeTypes.MATERIAL)
    .setType(CtControlTypes.COUNTER)
    .setOptions(
      CtRangeControlOptions
        .create()
        .setStep(15)
        .setMax(45)
    )
    .setValidators([
      CtControlValidator.create({name: 'required'} as CtControlValidator),
      CtControlValidator.create({name: "min", value: 0} as CtControlValidator),
      CtControlValidator.create({name: "max", value: 45} as CtControlValidator)
    ])
    .setReadonly(true);

  constructor() {
  }


  ngOnInit() {


  }

}
